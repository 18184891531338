.homepage-video {
  position: relative;
  margin: auto;
  width: 100%;
  height: 90vh;
  object-fit: cover;
  z-index: -100;
  filter: brightness(0.8);
}

.text-over-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.text-over-video > h1 {
  color: #fff;
  font-size: 5rem;
  margin-top: -80px;
  box-shadow: 1px 1px #000034;
  padding: 0 15px 25px 15px;
  line-height: 1;
  text-shadow: 2px 1px 1px #000034;
  margin-bottom: 12px;
}

.text-over-video > h4 {
  margin-top: 8px;
  color: #fff;
  text-align: center;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  animation: typing 5s steps(50, end), step-end infinite;
  animation-iteration-count: infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media screen and (max-width: 960px) {
  .text-over-video > h1 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .text-over-video > h1 {
    font-size: 3rem;
    padding: 0 12px 18px 12px;
  }
}
