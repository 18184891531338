* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

* h1,
h2 {
  font-family: "Bai Jamjuree", sans-serif;
}
